import React, { useState, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useFlexSearch } from 'react-use-flexsearch'
import Link from 'gatsby-theme-kn/src/components/atoms/Link'

import useClickOutside from 'gatsby-theme-kn/src/hooks/useClickOutside'

const Search = ({ className = '', searchTerm, locale }) => {
  const [query, setQuery] = useState(null)
  const [searchDropdownActive, setSearchDropdownActive] = useState(false)

  const clickOutsideRef = useRef()

  useClickOutside(clickOutsideRef, () => setSearchDropdownActive(false))

  const localSearchData = useStaticQuery(
    graphql`
      query {
        localSearchPages {
          index
          store
        }
      }
    `
  )
  const index = localSearchData.localSearchPages.index
  const store = localSearchData.localSearchPages.store

  let results = useFlexSearch(query, index, store)
  const finalResults = results.filter((result) => {
    if (result.full_slug.split('/')[0] === locale) {
      return result
    } else {
      return ''
    }
  })
  return (
    <div className={`search ${className}`} ref={clickOutsideRef}>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          setQuery(e.target[0].value)
        }}
      >
        <input
          name="query"
          className="form-textfield"
          id="searchBox"
          type="text"
          placeholder={searchTerm}
          autoComplete="off"
          onChange={(e) => {
            setQuery(e.target.value.length > 1 && e.target.value)
          }}
          onFocus={() => setSearchDropdownActive(true)}
        />
        <button type="submit">
          <i className="fas fa-search" aria-hidden="true"></i>
        </button>
      </form>
      {searchDropdownActive && (
        <div className="search-dropdown">
          <ul className="search-dropdown-results">
            {finalResults.length > 0 ? (
              finalResults.map(
                (result, index) =>
                  index < 6 && (
                    <li key={index}>
                      <Link link={`/${result.full_slug}`}>{result.name}</Link>
                    </li>
                  )
              )
            ) : (
              <>
                {/*  <li>
                  <b>Quicklinks:</b>
                </li>
                <li>
                  <Link link={`/${locale}`}>Startseite</Link>
                </li> */}
              </>
            )}
          </ul>
        </div>
      )}
    </div>
  )
}

export default Search
