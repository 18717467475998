import React, { useState, useEffect, useRef } from 'react'

const StoryblokImage = ({
  image,
  className,
  style,
  fixedSize,
  aspectRatio,
  title,
  alt,
  forceLoad,
}) => {
  const [containerHeight, setContainerHeight] = useState(null)
  const [containerWidth, setContainerWidth] = useState(null)
  const [imageSize, setImageSize] = useState(null)
  const [heightInPercentage, setHeightInPercentage] = useState(null)
  const [loading, setLoading] = useState(true)
  const containerRef = useRef(null)

  useEffect(() => {
    if (containerRef.current && !loading) {
      const width = containerRef.current.clientWidth
      setContainerWidth(Math.ceil(width / 50) * 50)
      if (aspectRatio && aspectRatio !== '') {
        const [ratioWidth, ratioHeight] = aspectRatio.split('by')
        const height = (containerWidth / ratioWidth) * ratioHeight
        const percentage = (100 * height) / containerWidth
        setContainerHeight(height.toFixed(0))
        setImageSize(`${containerWidth}x${containerHeight}`)
        setHeightInPercentage(percentage)
      } else {
        setImageSize(`${containerWidth}x0`)
      }
    }
    setLoading(false)
  }, [containerRef, imageSize, loading])

  !image && ''
  let originalSrc = image.filename
    ? image.filename
    : image.src
    ? image.src
    : image.length > 0 && image

  let imageService = 'https://img2.storyblok.com'

  let fileName = ''
  originalSrc.indexOf('https://') === 0
    ? (fileName = originalSrc.replace('https://a.storyblok.com/', ''))
    : (fileName = originalSrc.replace('//a.storyblok.com/', ''))

  let focus = image.focus
    ? `filters:focal(${image.focus}):format(webp)`
    : 'smart/filters:format(webp)'

  let imageSrc = ''

  if (loading) {
    imageSrc = ''
  } else if (!loading && fixedSize?.length > 0) {
    imageSrc = `${imageService}/${fixedSize}/${focus}/${fileName}`
  } else {
    imageSrc = `${imageService}/${imageSize}/${focus}/${fileName}`
  }

  return (
    <div
      ref={containerRef}
      style={
        aspectRatio && aspectRatio !== ''
          ? { height: `${heightInPercentage}%` }
          : { height: '' }
      }
      className={`relative ${className}`}
    >
      {aspectRatio && aspectRatio !== '' && (
        <div
          className="w-full"
          style={{ paddingBottom: `${heightInPercentage}%` }}
        ></div>
      )}
      {imageSize !== 'null' && !loading ? (
        <img
          loading={forceLoad ? 'eager' : 'auto'}
          src={imageSrc}
          alt={alt}
          className={`${
            aspectRatio && aspectRatio !== '' ? 'absolute top-0' : ''
          } ${className}`}
          title={title ? title : alt}
          style={style}
          fetchpriority={forceLoad ? 'high' : 'low'}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default StoryblokImage
