import React, { useRef, useState, useEffect, useCallback } from 'react'
import { Image, Transformation } from 'cloudinary-react'

const CloudinaryImage = ({
  image,
  type,
  imageWidth,
  imageHeight,
  fillColor,
  gravity,
  className,
  alt,
  title,
  storyblokFocus,
  forceLoad,
}) => {
  const [containerWidth, setContainerWidth] = useState(null)
  const [loading, setLoading] = useState(true)
  let imageId = null

  let aFocusX = null
  let aFocusY = null
  let aGravity = gravity ? gravity : fillColor ? '' : 'face'

  const containerRef = useRef(null)

  const calcContainerWidth = () => {
    const width = containerRef.current.clientWidth
    setContainerWidth(Math.ceil(width / 50) * 50)
    //console.log(containerWidth)
  }

  if (storyblokFocus) {
    let sbImagePathSegments = image.split('/')
    let sbFocusPointSegments = storyblokFocus.split(':')[0]
    let sbFocusPointX = parseInt(sbFocusPointSegments.split('x')[0])
    let sbFocusPointY = parseInt(sbFocusPointSegments.split('x')[1])
    let imageSizeStr = sbImagePathSegments[sbImagePathSegments.length - 3]
    let origImageW = parseInt(imageSizeStr.split('x')[0])
    let origImageH = parseInt(imageSizeStr.split('x')[1])
    let widthPercentage = Math.round(sbFocusPointX / (origImageW / 100)) / 100
    let heightPercentage = Math.round(sbFocusPointY / (origImageH / 100)) / 100
    aFocusX = `w_mul_${widthPercentage}`
    aFocusY = `h_mul_${heightPercentage}`
    aGravity = 'xy_center'
  }

  if (type === 'cloudinariedStoryblokImage') {
    let transformedUrl = ''
    if (image.indexOf('https://a.storyblok.com') !== -1) {
      transformedUrl = image.replace(
        'https://a.storyblok.com',
        'https://img2.storyblok.com'
      )
    } else {
      transformedUrl = image.replace(
        '//a.storyblok.com',
        'https://img2.storyblok.com'
      )
    }
    imageId = transformedUrl
  } else if (type === 'cloudinaryImage') {
    const splittedUrl = image.split('/')

    let publicId = null

    // Es kann Cloudinary-URLs dieses Schemas geben, zB.:
    // https://res.cloudinary.com/koenig-neurath/image/upload/a_exif,b_transparent,c_lpad,g_north,h_180,q_auto:best,w_180/v1616492877/zip.png
    if (splittedUrl[4] === 'image' && splittedUrl[5] === 'upload') {
      publicId = splittedUrl[splittedUrl.length - 1]
    } else {
      publicId = splittedUrl[splittedUrl.length - 2]
    }
    imageId = publicId
  }

  useEffect(() => {
    if (containerRef.current && !imageWidth) {
      calcContainerWidth()
    }
    setLoading(false)
  }, [containerRef, calcContainerWidth, loading])

  return (
    <div ref={containerRef} className={className}>
      {type === 'cloudinariedStoryblokImage' && !loading && (
        <Image
          cloudName={process.env.CLOUDINARY_NAME}
          publicId={imageId}
          loading={forceLoad ? 'eager' : 'auto'}
          type="fetch"
          className={className}
          alt={alt}
          title={title ? title : alt}
        >
          <Transformation
            fetchFormat="auto"
            quality="auto:good"
            gravity={aGravity}
            width={imageWidth ? imageWidth : containerWidth}
            height={imageHeight ? imageHeight : ''}
            background={fillColor ? `#${fillColor}` : ''}
            crop={fillColor ? 'pad' : 'fill'}
            dpr="auto"
            x={aFocusX}
            y={aFocusY}
          />
        </Image>
      )}
      {type === 'cloudinaryImage' && !loading && (
        <Image
          cloudName={process.env.CLOUDINARY_NAME}
          publicId={imageId}
          className={className}
          loading={forceLoad ? 'eager' : 'auto'}
        >
          <Transformation
            fetchFormat="auto"
            quality="auto:good"
            gravity={gravity ? gravity : ''}
            width={imageWidth ? imageWidth : containerWidth}
            height={imageHeight ? imageHeight : ''}
            background={fillColor ? `#${fillColor}` : ''}
            crop={fillColor ? 'pad' : 'fill'}
            dpr="auto"
          />
        </Image>
      )}
    </div>
  )
}

export default CloudinaryImage
