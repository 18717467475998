import React, { useState, useEffect, Fragment } from 'react'
import Link from '../atoms/Link'
import Image from '../atoms/Image'
import Logo from '../../assets/images/kn-logo.svg'
import { useTranslation } from 'react-i18next'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Search from './Search'

gsap.registerPlugin(ScrollTrigger)

const MicroHeader = ({
  navData,
  locale,
  alternates,
  searchTerm,
  loginTerm,
  mobileMenuClickHandler,
  microsite,
}) => {
  const [activeMenuState, setActiveMenuState] = useState(null)
  const [headerElement, setHeaderElement] = useState(null)
  const { t } = useTranslation()

  const toggleMenu = (e, index) => {
    e.preventDefault()
    const newActiveMenuState = activeMenuState !== index ? index : null
    setActiveMenuState(newActiveMenuState)
  }

  const menuBlurHandler = () => {
    setActiveMenuState(null)
  }

  useEffect(() => {
    if (headerElement) {
      ScrollTrigger.create({
        start: 'top -40px',
        end: 99999,
        onEnter: () => {
          headerElement.classList.add('scrolled-state')
        },
        onLeaveBack: () => {
          headerElement.classList.remove('scrolled-state')
        },
      })
    }
  }, [headerElement])

  return (
    <header
      ref={setHeaderElement}
      className="header-navbar header-navbar-micro"
    >
      <div className="header-navbar-bg-fade">
        <div className="header-navbar-wrapper">
          <div className="meta-nav">
            <nav className="navbar navbar-expand-lg meta-nav-content">
              <ul className="ms-auto nav navbar-nav">
                {alternates?.map((link) => {
                  return (
                    <li
                      key={`micro-nav-item-alternates-${link.full_slug}`}
                      className="nav-item"
                    >
                      <Link className="nav-link" link={`/${link.full_slug}`}>
                        <span>{link.lang.toUpperCase()}</span>
                      </Link>
                    </li>
                  )
                })}
                {alternates?.length > 0 && (
                  <li className="nav-item">
                    <span className="meta-nav-seperator">|</span>
                  </li>
                )}
                {/* Links innerhalb der Hauptnavigation (Fachhandel, Berater,
                Presse) */}
                {navData?.length > 0 &&
                  navData?.map(
                    (item, index) =>
                      item.component === 'nav_link' && (
                        <li
                          key={`micro-nav-item-${item.path}`}
                          className="nav-item"
                          key={index}
                        >
                          <Link className="nav-link" link={item.path}>
                            <span>{item.title}</span>
                          </Link>
                        </li>
                      )
                  )}
                {/*  <li className="nav-item">
                  <span className="meta-nav-seperator">|</span>
                </li> */}
                {/* Der Login-Bereich bleibt statisch */}
                <li className="nav-item">
                  <a
                    className={`nav-link has-childs ${
                      activeMenuState === 'login' && 'active'
                    }`}
                    target="_blank"
                    rel="noopener"
                    href={t('generic.spa.paths.partner_portal_info')}
                    // onClick={(e) => toggleMenu(e, 'login')}
                    // onBlur={() => menuBlurHandler()}
                  >
                    <span>{loginTerm}</span>
                    <i className="fal fa-user" style={{ display: 'none' }} />
                  </a>

                  <div
                    className={`meta-nav-menu ${
                      activeMenuState === 'login' && 'show'
                    }`}
                    data-id="login-menu"
                  >
                    <form data-id="partnerportal-login-form">
                      <p className="alert alert-danger d-none">
                        <small>{t('generic.login_menu.error')}</small>
                      </p>
                      <div className="mb-1">
                        <input
                          placeholder={t('generic.login_menu.email')}
                          type="email"
                          className="form-control"
                          data-id="inputEmail"
                        />
                      </div>
                      <div className="mb-1">
                        <input
                          placeholder={t('generic.login_menu.password')}
                          type="password"
                          className="form-control"
                          data-id="inputPassword"
                        />
                      </div>
                      <div className="form-check">
                        <label className="form-check-label">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            data-id="inputRememberMe"
                          />
                          {t('generic.login_menu.remember_me')}
                        </label>
                      </div>
                      <button type="submit" className="btn btn-sm btn-primary">
                        {t('generic.login_menu.login')}
                      </button>
                      <br />
                      <a
                        href="#"
                        /* onClick={
                          'window.spa_showResetPasswordModal(); return false;'
                        } */
                        className=""
                      >
                        {t('generic.login_menu.password_forgotten')}
                      </a>
                      <a
                        className="info-link"
                        href={t('generic.spa.paths.partner_portal_info')}
                      >
                        {t('generic.login_menu.info')} &nbsp;
                        <i className="fal fa-chevron-right" />
                      </a>
                    </form>
                    <div
                      data-id="partnerportal-logged-in-menu"
                      style={{ display: 'none' }}
                    >
                      <h4>{t('generic.login_menu.welcome')}</h4>
                      <a
                        data-id="partnerportal-logout-btn"
                        //href="javascript:;"
                        className="btn btn-sm btn-primary"
                      >
                        {t('generic.login_menu.logout')}
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
          <div className="header-navbar-container">
            <nav className="navbar navbar-expand-lg main-nav">
              <Link link={`/${locale}/`} className="navbar-brand">
                <Image
                  alt="König + Neurath Logo"
                  className="brand-logo"
                  image={Logo}
                  forceLoad={true}
                />
                <div className="brand-title">
                  <span>König + Neurath</span>
                </div>
                {microsite && (
                  <span
                    className={`brand-subtitle brand-${microsite.toLowerCase()}`}
                  >
                    {microsite}
                  </span>
                )}
              </Link>
              <button
                className="navbar-toggler"
                onClick={() => mobileMenuClickHandler()}
              >
                <i className="fal fa-bars" />
              </button>
              <div className="collapse navbar-collapse" id="navDataigation">
                <div className="order-2 nav navbar-nav search-nav">
                  <div className="nav-item">
                    <Search searchTerm={searchTerm} locale={locale} />
                  </div>
                </div>
                {/* Hauptnavigationspunkte */}
                <ul className="me-auto navbar-nav">
                  {navData &&
                    navData?.map(
                      (item, index) =>
                        item.component === 'main_nav_block' && (
                          /* Für den Bereich "Partner" muss man angemeldet sein */
                          <li
                            className={`nav-item ${
                              item.needs_session === true && 'd-none'
                            }`}
                            data-needs-session={item.needs_session}
                            key={index}
                          >
                            <a
                              className={`nav-link has-childs ${
                                activeMenuState === index && 'active'
                              }`}
                              href={item.path}
                              data-toggle-target-menu={`#${item._uid}`}
                              onClick={(e) => toggleMenu(e, index)}
                              //onBlur={() => menuBlurHandler()}
                            >
                              <span>{item.title.toUpperCase()}</span>
                              <i className="fal fa-chevron-down" />
                            </a>
                          </li>
                        )
                    )}
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
      {/* Hover-Menu */}
      {navData &&
        navData?.map(
          (navData, index) =>
            navData.component === 'main_nav_block' && (
              <div
                className={`main-nav-menu ${
                  activeMenuState === index && 'show'
                }`}
                id={navData._uid}
                key={index}
              >
                <div
                  className="main-nav-menu-overlay"
                  onClick={() => setActiveMenuState(false)}
                />
                <div className="main-nav-menu-container">
                  <div className="main-nav-menu-row">
                    {/* Linke Spalte */}
                    <div className="main-nav-menu-cell">
                      <div
                        className="main-nav-menu-2nd-level"
                        onClick={() => menuBlurHandler()}
                      >
                        {navData &&
                          navData.main_nav_group.map(
                            (navDataItem, index) =>
                              navDataItem.col === 'left' && (
                                <Fragment key={index}>
                                  <Link
                                    className="nav-main-category-link"
                                    link={navDataItem.path}
                                  >
                                    <span>{navDataItem.title}</span>
                                  </Link>
                                  {navDataItem.nav_link?.length > 0 && (
                                    <ul className="nav flex-column">
                                      {navDataItem.nav_link.map(
                                        (navItem, index) => (
                                          <li
                                            className="nav-item"
                                            data-needs-role={
                                              navItem.required_role &&
                                              navItem.required_role
                                            }
                                            key={index}
                                          >
                                            {navItem.alternate_link_markup ? (
                                              <>
                                                {navItem.alternate_link_markup}
                                              </>
                                            ) : (
                                              <Link
                                                className="nav-link"
                                                link={navItem.path}
                                              >
                                                <span>
                                                  {navItem.strong == true && (
                                                    <strong>
                                                      {navItem.title}
                                                    </strong>
                                                  )}
                                                  {navItem.strong != true && (
                                                    <>{navItem.title}</>
                                                  )}
                                                </span>
                                              </Link>
                                            )}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  )}
                                </Fragment>
                              )
                          )}
                      </div>
                    </div>
                    {/* Mittlere Spalte */}
                    {navData &&
                      navData.main_nav_group.map(
                        (navDataItem, index) =>
                          navDataItem.col === 'middle' && (
                            <div className="main-nav-menu-cell" key={index}>
                              <div
                                className="main-nav-menu-2nd-level"
                                onClick={() => menuBlurHandler()}
                              >
                                <Link
                                  className="nav-main-category-link"
                                  link={navDataItem.path}
                                >
                                  <span>{navDataItem.title}</span>
                                </Link>
                                {navDataItem.nav_link?.length > 0 && (
                                  <ul className="nav flex-column">
                                    {navDataItem.nav_link.map(
                                      (navItem, index) => (
                                        <li
                                          className="nav-item"
                                          data-needs-role={
                                            navItem.required_role &&
                                            navItem.required_role
                                          }
                                          key={index}
                                        >
                                          {navItem.alternate_link_markup ? (
                                            <>{navItem.alternate_link_markup}</>
                                          ) : (
                                            <Link
                                              className="nav-link"
                                              link={navItem.path}
                                            >
                                              <span>
                                                {navItem.strong == true && (
                                                  <strong>
                                                    {navItem.title}
                                                  </strong>
                                                )}
                                                {navItem.strong != true && (
                                                  <>{navItem.title}</>
                                                )}
                                              </span>
                                            </Link>
                                          )}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                )}
                              </div>
                            </div>
                          )
                      )}
                    {/* Rechte Spalte */}
                    {navData &&
                      navData.main_nav_group.map(
                        (navDataItem, index) =>
                          navDataItem.col === 'right' && (
                            <div className="main-nav-menu-cell" key={index}>
                              <div
                                className="main-nav-menu-2nd-level"
                                onClick={() => menuBlurHandler()}
                              >
                                <Link
                                  className="nav-main-category-link"
                                  link={navDataItem.path}
                                >
                                  <span>{navDataItem.title}</span>
                                </Link>
                                {navDataItem.nav_link?.length > 0 && (
                                  <ul className="nav flex-column">
                                    {navDataItem.nav_link.map(
                                      (navItem, index) => (
                                        <li
                                          className="nav-item"
                                          data-needs-role={
                                            navItem.required_role &&
                                            navItem.required_role
                                          }
                                          key={index}
                                        >
                                          {navItem.alternate_link_markup ? (
                                            <>{navItem.alternate_link_markup}</>
                                          ) : (
                                            <Link
                                              className="nav-link"
                                              link={navItem.path}
                                            >
                                              <span>
                                                {navItem.strong == true && (
                                                  <strong>
                                                    {navItem.title}
                                                  </strong>
                                                )}
                                                {navItem.strong != true && (
                                                  <>{navItem.title}</>
                                                )}
                                              </span>
                                            </Link>
                                          )}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                )}
                              </div>
                            </div>
                          )
                      )}
                  </div>
                </div>
              </div>
            )
        )}
      <nav id="mobileNav" style={{ visibility: 'hidden' }}>
        <div className="search-nav">
          <div className="search">
            <form action="#" method="get" target="_blank">
              <input
                name="q"
                className="form-textfield"
                id="searchBox"
                type="text"
                placeholder={searchTerm}
              />
              <button type="submit">
                <i className="fas fa-search" aria-hidden="true" />
              </button>
            </form>
          </div>
        </div>
        <div />
      </nav>
    </header>
  )
}

export default MicroHeader
