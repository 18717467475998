import React from 'react'

const StaticImage = ({ image, className, style, title, alt, forceLoad }) => {
  const imageSrc = image

  return (
    <img
      loading={forceLoad ? 'eager' : 'auto'}
      src={imageSrc}
      alt={alt}
      className={className}
      title={title ? title : alt}
      style={style}
    />
  )
}

export default StaticImage
