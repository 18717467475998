import React from 'react'

import { useTranslation } from 'react-i18next'

const FloatingContact = ({ hidden }) => {
  const { t } = useTranslation()

  return (
    <section className={`floating-contact-block ${!hidden ? '' : 'hidden'}`}>
      <div className="floating-contact-block-container">
        <div className="floating-contact-block-wrapper">
          <div className="floating-contact-block-row">
            <div className="floating-contact-block-panel">
              <div className="floating-contact-block-info">
                <div className="floating-contact-block-info-teaser">
                  <i className="fa fa-phone" aria-hidden="true"></i>
                </div>
                <div className="floating-contact-block-info-content">
                  <div className="floating-contact-block-info-text">
                    <p className="intro">{t('contact_space.intro')}</p>
                    <p className="title">{t('contact_space.title')}</p>
                    <p>
                      {t('contact_space.tel_link').length > 0 && (
                        <>
                          <a href={t('contact_space.tel_link')}>
                            <i className="fas fa-phone fa-flip-horizontal" />{' '}
                            {t('contact_space.tel')}
                          </a>
                          <br />
                        </>
                      )}
                      {t('contact_space.contact_link').length > 0 && (
                        <>
                          <a href={t('contact_space.contact_link')}>
                            <i className="fas fa-at" />{' '}
                            {t('contact_space.contact')}
                          </a>
                          <br />
                        </>
                      )}
                      {t('contact_space.town').length > 0 && (
                        <>
                          <i className="fas fa-map-marker-alt" />{' '}
                          {t('contact_space.floor')}
                          {t('contact_space.floor') !== '' ? <br /> : ''}
                          {t('contact_space.street')}
                          <br />
                          {t('contact_space.town')}
                        </>
                      )}
                    </p>
                  </div>
                  <div className="footer-social-media-bar">
                    <a
                      href="https://de.linkedin.com/company/k-nig-neurath"
                      target="_blank"
                      className="socialmedia-link-white"
                    >
                      <i className="fab fa-linkedin" aria-hidden="true"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/koenig_neurath_ag/"
                      className="socialmedia-link-white"
                    >
                      <i className="fab fa-instagram" aria-hidden="true"></i>
                    </a>
                    <a
                      href="https://www.facebook.com/koenig.neurath/"
                      className="socialmedia-link-white"
                    >
                      <i className="fab fa-facebook-f" aria-hidden="true"></i>
                    </a>

                    {/* <a
                      href="https://www.xing.com/companies/k%C3%B6nig+neurathag"
                      target="_blank"
                      className="socialmedia-link-white"
                    >
                      <i className="fab fa-xing" aria-hidden="true"></i>
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FloatingContact
