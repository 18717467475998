import Cookies from 'universal-cookie'
const cookies = new Cookies()

export function apiURL(path) {
  return process.env.PARTNER_PORTAL_URL + path
}

export function getUserName() {
  return cookies.get('user_name') || ''
}

export function cookieSettings() {
  return {
    path: '/',
  }
}

export function displayNavItem(item) {
  if (!item.needs_session && !item.required_role) {
    return true
  }
  if (item.needs_session === true && cookies.get('auth_token')) {
    return true
  }
  if (item.required_role && hasUserRole(item.required_role)) {
    return true
  }
  return false
}

export function auth_token() {
  return cookies.get('auth_token') || ''
}
export function isAdmin() {
  return hasUserRole('admin')
}
export function canCreateUploadList() {
  return hasUserRole('create_upload_list')
}
export function div(value) {
  if (value) {
    return Math.round(value / 10) / 100
  }
}
export function graphPercent(v1, v2) {
  return (v1 / v2) * 100 + '%'
}

export function formatKW(value) {
  if (value) {
    return value.replace('.', ' ')
  }
}

export function formatDate(string) {
  var date = new Date(string)
  return [
    ('0' + date.getDate()).slice(-2),
    ('0' + (date.getMonth() + 1)).slice(-2),
    date.getFullYear(),
  ].join('.')
}

export function calendarWeek(value, options) {
  var week = getWeekNumber(new Date(value))
  return 'KW ' + week[1] + ' ' + week[0]
}

export function archiveUrl(archivobject, kundennr) {
  return apiURL(
    'order_confirmation?archive_object=' +
      archivobject +
      '&customer_number=' +
      kundennr
  )
}

export function dateFormatter(date) {
  if (!date) {
    return ''
  }
  let d = new Date(date)
  let dayOfMonth = d.getDate()
  let month = d.getMonth()
  let year = d.getFullYear()

  return pad(dayOfMonth) + '.' + pad(month + 1) + '.' + year
}

function pad(n) {
  return n < 10 ? '0' + n : n
}

function getWeekNumber(d) {
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7))
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
  var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7)
  return [d.getUTCFullYear(), weekNo]
}

function hasUserRole(role) {
  return getUserRoles().indexOf(role) !== -1
}

function getUserRoles() {
  return (cookies.get('user_roles') || '')
    .replace(/mdb_uc_/g, 'media_upload_category_')
    .split(',')
    .filter(String)
}
