import React, { useState, useEffect } from 'react'
import StaticImage from './Images/StaticImage'
import CloudinaryImage from './Images/CloudinaryImage'
import StoryblokImage from './Images/StoryblokImage'

const Image = ({
  image,
  className,
  style,
  imageWidth,
  imageHeight,
  fillColor,
  forceLoad,
  gravity,
  useStoryblokImage,
  hasTransforms,
  alt,
  title,
}) => {
  let imageType = null
  let imageSrc = ''

  image?.filename ? (imageSrc = image.filename) : (imageSrc = image)

  if (image) {
    if (!useStoryblokImage && /^(?=.*\bstoryblok\b).*$/.test(imageSrc)) {
      imageType = 'cloudinariedStoryblokImage'
    } else if (useStoryblokImage && /^(?=.*\bstoryblok\b).*$/.test(imageSrc)) {
      imageType = 'storyblokImage'
    } else if (hasTransforms) {
      imageType = 'staticImage'
    } else if (/^(?=.*\bcloudinary\b).*$/.test(imageSrc)) {
      imageType = 'cloudinaryImage'
    } else {
      imageType = 'staticImage'
    }
  }

  const IMAGE_TYPES = {
    cloudinariedStoryblokImage: (
      <CloudinaryImage
        image={imageSrc}
        alt={alt}
        title={title}
        className={className}
        style={style}
        type={imageType}
        imageWidth={imageWidth}
        imageHeight={imageHeight}
        fillColor={fillColor}
        gravity={gravity}
        storyblokFocus={image && image.focus ? image.focus : null}
        forceLoad={forceLoad}
      />
    ),
    staticImage: (
      <StaticImage
        image={image}
        alt={alt}
        title={title}
        className={className}
        style={style}
        forceLoad={forceLoad}
      />
    ),
    cloudinaryImage: (
      <CloudinaryImage
        image={imageSrc}
        alt={alt}
        title={title}
        className={className}
        style={style}
        type={imageType}
        imageWidth={imageWidth}
        imageHeight={imageHeight}
        fillColor={fillColor}
        gravity={gravity}
        forceLoad={forceLoad}
      />
    ),
    storyblokImage: (
      <StoryblokImage
        image={image}
        fixedSize={`${
          imageWidth?.length > 0 ? imageWidth + 'x' + imageHeight : ''
        }`}
        forceLoad={forceLoad}
      />
    ),
  }

  const ImageState = ({ imageType }) => {
    return <>{IMAGE_TYPES[imageType]}</>
  }

  return (
    <>
      <ImageState imageType={imageType} />
    </>
  )
}

export default Image
